import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

const routes = [
    { path: '/', component: () => import("@/views/Home.vue") },
    { path: '/cookie', component: () => import("@/views/Cookie.vue") },
    { path: '/legal', component: () => import("@/views/Legal.vue") },
    { path: '/privacy-policy', component: () => import("@/views/PrivacyPolicy.vue") },
    { path: '/login', component: () => import("@/views/user/Login.vue") },
    { path: '/signup', component: () => import("@/views/user/SignUp.vue") },
    { path: '/recovery-password', component: () => import("@/views/user/RecoveryPassword.vue") }
];


const router = new VueRouter({
    routes
});

const pathsNotAvailableIfLogged = ['/login', '/signup', '/recovery-password'];

router.beforeEach((to, from, next) => {
    store.commit("setIsDrawerOpen", false);

    if (store.getters.userInfo && pathsNotAvailableIfLogged.includes(to.path)) {
        next("/");
        return;
    }

    // if (to.matched.some((record) => record.meta.requiresAuth)) {
    //     store.getters.userData ? next() : next("/login");
    // } else {
    //     next();
    // }

    next();
});

export default router;