import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";
import { loadWeb3, loadBlockchainData } from '@/services/web3login.js'
import router from '@/router.js'

const user = {
  state: () => ({
    walletInfo: null,
    userInfo: null,
  }),
  mutations: {
    setWalletInfo(state, walletInfo) {
      state.walletInfo = walletInfo
    },
    setUserInfo(state, user) {
      state.userInfo = user
    },
  },
  actions: {
    async connectWallet({ commit, dispatch }) {
      await loadWeb3();
      const { chainId, walletInfo } = await loadBlockchainData();

      commit("setChainId", chainId);
      commit("setWalletInfo", walletInfo);
      await dispatch("getVideos");
    },

    async disconnectWallet({ commit, dispatch }) {
      commit("setChainId", null);
      commit("setWalletInfo", null);
      commit("isNFTMissing", false);
      await dispatch("getVideos");
    },

    logout() {
      getAuth().signOut();
    },
    
    async loginGoogle({ commit, dispatch }) {
      commit("startLoading");

      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      try {
        const response = await signInWithPopup(auth, provider);
        commit("setUserInfo", response.user);
        router.replace("/");
      } catch (err) {
        console.error(err);
        dispatch("createErrorNotification", { text: err.code })
      } finally {
        commit("endLoading");
      }
    },
    async loginWithEmailAndPassword({ commit, dispatch }, { email, password }) {
      commit("startLoading");

      const auth = getAuth();

      try {
        const response = await signInWithEmailAndPassword(auth, email, password);
        commit("setUserInfo", response.user);
        router.replace("/");
      } catch (err) {
        console.error(err);
        dispatch("createErrorNotification", { text: err.code })
      } finally {
        commit("endLoading");
      }
    },
    async signUpWithEmailAndPassword({ commit, dispatch }, { email, password }) {
      commit("startLoading");

      const auth = getAuth();

      try {
        const response = await createUserWithEmailAndPassword(auth, email, password);
        commit("setUserInfo", response.user);
        dispatch("createNotification", { text: "sign_up_success" });
        router.replace("/");
      } catch (err) {
        console.error(err);
        dispatch("createErrorNotification", { text: err.code })
      } finally {
        commit("endLoading");
      }
    },

    async sendPasswordResetEmail({ commit, dispatch }, email) {
      commit("startLoading");

      const auth = getAuth();

      try {
        await sendPasswordResetEmail(auth, email);
        dispatch("createNotification", { text: "check_email" });
        router.replace("/");
      } catch (err) {
        console.error(err);
        dispatch("createErrorNotification", { text: err.code })
      } finally {
        commit("endLoading");
      }
    },

    setAuthStateChanged({ commit, dispatch }) {
      getAuth().onAuthStateChanged(async user => {
        if (!user) {
          commit("setUserInfo", null);
        } else {
          commit("setUserInfo", user);
        }
        await dispatch("getVideos", user?.accessToken);
      });
    },
  },
  getters: {
    walletInfo: state => state.walletInfo,
    userInfo: state => state.userInfo,
  }
};

export default user;