<template>
  <v-navigation-drawer
    v-model="isDrawerOpen"
    width="auto"
    height="auto"
    temporary
    hide-overlay
    permanent
    color="var(--color-bg)"
  >
    <v-list nav dark>
      <v-list-item v-if="!userInfo" to="/login" class="button">
        <v-list-item-title>
          {{ $t("button.login") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="logout" class="button">
        <v-list-item-title>
          {{ $t("button.logout") }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="!walletInfo" @click="connect" class="button">
        <v-list-item-title>
          {{ $t("button.connect") }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="walletInfo" @click="disconnect" class="button">
        <v-list-item-title>
          {{ $t("button.disconnect_wallet") }}
        </v-list-item-title>
      </v-list-item>

      <v-sheet v-if="walletInfo" rounded color="var(--color-accent)">
        <v-card elevation="9" shaped>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("message.wallet_title")
              }}</v-list-item-title>
              <v-list-item-subtitle> {{$t("message.address")}} </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{
                  walletInfo.address.substring(
                    0,
                    walletInfo.address.length / 7
                  ) +
                  "..." +
                  walletInfo.address.substring(
                    (walletInfo.address.length * 6) / 7
                  )
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{$t("message.network")}}</v-list-item-title>
              <v-list-item-subtitle>{{
                network[chainId].name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{$t("message.balance")}}</v-list-item-title>
              <v-list-item-subtitle
                >{{ walletInfo.balance }} {{ network[chainId].currency }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-sheet>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import network from "@/assets/network.json";

export default {
  name: "Drawer",
  components: {},

  data() {
    return {
      network: network,
    };
  },
  methods: {
    async connect() {
      await this.connectWallet();
      window.ethereum.on("chainChanged", async () => {
        if (this.walletInfo) {
          await this.connectWallet();
        }
      });
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length === 0) {
          await this.disconnectWallet();
        }
      });
    },
    async disconnect() {
      this.showWalletInfoModal = false;
      await this.disconnectWallet();
    },
    ...mapActions(["logout", "connectWallet", "disconnectWallet"]),
  },
  computed: {
    ...mapGetters(["isDrawerOpen", "userInfo", "walletInfo", "chainId"]),
  },
};
</script>

<style>
.button {
  background-color: var(--color-gallery);
  text-align: center;
  margin: 12px;
  border: solid 1px var(--color-accent);
}

</style>