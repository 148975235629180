import Web3 from 'web3'

export const loadWeb3 =  async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum)
    await window.ethereum.enable()
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider)
  } else {
    window.alert("Non-Ethereum browser detcet. You should consider trying MetaMask!")
  }
}

export const loadBlockchainData = async () => {
  const web3 = window.web3
  const chainId = await web3.eth.getChainId();
  const accounts = await web3.eth.getAccounts();
  const balance = await web3.eth.getBalance(accounts[0]);
  return { chainId, walletInfo: { address: accounts[0], balance: Math.trunc(balance / 10**15)/1000 }}
}
