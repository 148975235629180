<template>
  <v-app>
    <Title :title="title"></Title>
    <AppBar />
    <div class="wrapper">
      <v-main>
        <Drawer v-if="isDrawerOpen"/>
        <v-overlay :value="isLoading" z-index="10">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container fill-height style="align-items: unset">
          <router-view />
        </v-container>
      </v-main>
      <Notification />
    </div>
    <Footer />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import Footer from "./components/Footer.vue";
import Drawer from "./components/Drawer.vue";
import Title from './components/Title.vue';
import Notification from "@/components/Notification.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  title: "",
  data() {
    return {};
  },
  components: {
    AppBar,
    Footer,
    Notification,
    Drawer,
    Title
  },
  async created() {
    await this.loadConfig();
    await this.loadSiteConfig();
    this.setAuthStateChanged();
    
    const resp = await fetch("/config.json");
    const config = await resp.json();
    this.title = config.title;
  },
  methods: {
    ...mapActions([
      "loadConfig",
      "loadSiteConfig",
      "setAuthStateChanged",
      "closeNotification",
    ]),
  },
  computed: {
    ...mapGetters(["isLoading", "siteConfig", "notification", "isDrawerOpen"]),
  },
};
</script>

<style>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--color-text);
  background-color: var(--color-gallery);
}

.v-application a,
.v-application a:visited {
  color: var(--color-text);
  text-decoration: none;
}

.v-navigation-drawer, .v-navigation-drawer--fixed {
  z-index: 7;
}
</style>
