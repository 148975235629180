<template>
  <v-app-bar
    color="var(--color-bg)"
    flat
    app
    height="100"
    extension-height="64"
  >
    <router-link to="/">
      <img
        :class="$vuetify.breakpoint.mobile ? 'ml-4' : 'ml-6'"
        

        src="@/assets/custom/logo.png"
      />
    </router-link>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon
      @click="toggleDrawer"
      v-if="$vuetify.breakpoint.mobile"
    ></v-app-bar-nav-icon>

    <Locale />

    <div v-if="!$vuetify.breakpoint.mobile">
      <v-chip
        v-if="walletInfo && network[chainId]"
        class="mr-2 text-capitalize"
        dark
        >{{ network[chainId].name }}</v-chip
      >
      <v-chip v-if="walletInfo && network[chainId]" class="mr-2" dark
        >{{ walletInfo.balance }} {{ network[chainId].currency }}</v-chip
      >

      <v-btn
        v-if="!walletInfo"
        class="mr-4"
        color="var(--color-accent)"
        rounded
        @click="connect"
        >{{ $t("button.connect") }}</v-btn
      >
      <v-btn
        v-else
        class="mr-4"
        color="var(--color-accent)"
        rounded
        @click="showWalletInfoModal = true"
        >{{
          walletInfo.address.substring(0, walletInfo.address.length / 7) +
          "..." +
          walletInfo.address.substring((walletInfo.address.length * 6) / 7)
        }}</v-btn
      >

      <v-btn
        v-if="!userInfo"
        class="mr-4"
        color="var(--color-accent)"
        rounded
        to="/login"
        active-class="active-button"
        >{{ $t("button.login") }}</v-btn
      >
      <v-btn
        v-else
        class="mr-4"
        color="var(--color-accent)"
        rounded
        @click="logout"
        >{{ $t("button.logout") }}</v-btn
      >
    </div>
      

    <v-dialog v-model="showModal" width="500">
      <v-card dark>
        <v-card-title
          class="text-h5 pl-4 pr-2 py-2 card-title"
          color="var(--color-accent)"
        >
          {{ $t("message.connect_title") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="showModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-4 white--text">
          {{ $t("message.connect_text") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="var(--color-metamask)" @click="openMetamask">
            <img class="mr-2 icon" src="@/assets/icons/metamask.png" />
            {{ $t("button.metamask") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showWalletInfoModal" width="500">
      <v-card dark>
        <v-card-title
          class="text-h5 pl-4 pr-2 py-2 card-title"
          color="var(--color-accent)"
        >
          {{ $t("message.wallet_title") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="showWalletInfoModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-6 white--text d-flex flex-column">
          <span class="pb-2">{{ $t("message.wallet_text") }}</span>
          <div class="pt-4 d-flex justify-space-around">
            <v-chip
              class="text-center"
              :small="$vuetify.breakpoint.mobile"
              v-if="walletInfo"
              rounded
              >{{ walletInfo.address }}</v-chip
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="px-2"
                  min-width="32"
                  rounded
                  @click="copyWalletAddress"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-snackbar
                  :timeout="snackbarTimeout"
                  color="var(--color-accent)"
                  v-model="snackbar"
                >
                  <b>{{ $t("notification.copy_address") }}</b>
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      text
                      rounded
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-snackbar>
              </template>
              <span>{{ $t("tooltip.copy_address") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="chainId && network[chainId] && network[chainId].url"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="px-2"
                  min-width="32"
                  rounded
                  @click="openExplorer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.etherscan") }}</span>
            </v-tooltip>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="var(--color-accent)" @click="disconnect">
            {{ $t("button.disconnect") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="isNFTMissing" v-slot:extension="{}">
      <span>
        {{ $t("message.missing_NFT") }}
        <a class="text-decoration-underline" :href="tokenUrl" target="_blank">
          {{ $t("message.here") }}
        </a>
      </span>
      <v-spacer></v-spacer>
      <v-icon class="close" @click="setNFTMissing(false)">mdi-close</v-icon>
    </template>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import network from "@/assets/network.json";
import Locale from "./Locale.vue";

export default {
  components: { Locale },
  name: "AppBar",

  data: () => ({
    showModal: false,
    showWalletInfoModal: false,
    network: network,
    dialog: false,
    drawer: false,
  }),

  methods: {
    async connect() {
      if (!window.web3) {
        this.showModal = true;
      } else {
        await this.connectWallet();
        window.ethereum.on("chainChanged", async () => {
          if (this.walletInfo) {
            await this.connectWallet();
          }
        });
        window.ethereum.on("accountsChanged", async (accounts) => {
          if (accounts.length === 0) {
            await this.disconnectWallet();
          }
        });
      }
    },

    async disconnect() {
      this.showWalletInfoModal = false;
      await this.disconnectWallet();
    },

    async copyWalletAddress() {
      this.createNotification({text: "copy_address", type: "info"});
      await navigator.clipboard.writeText(this.walletInfo?.address);
    },

    openMetamask() {
      window.open("https://metamask.io/", "_blank").focus();
    },

    openExplorer() {
      window
        .open(
          `${this.network[this.chainId].url}/address/${
            this.walletInfo?.address
          }`,
          "_blank"
        )
        .focus();
    },

    ...mapActions([
      "login",
      "logout",
      "connectWallet",
      "disconnectWallet",
      "setNFTMissing",
      "createNotification"
    ]),
    ...mapMutations(["toggleDrawer"]),
  },

  computed: {
    ...mapGetters([
      "userInfo",
      "walletInfo",
      "chainId",
      "isNFTMissing",
      "tokenUrl",
    ]),
  },
};
</script>

<style scoped>
.v-btn,
.v-chip {
  color: var(--color-text) !important;
  font-weight: bold;
}

.v-btn:before {
  background-color: transparent;
}

.icon {
  height: 16px;
  width: 16px;
}

.card-title {
  background-color: var(--color-accent);
}

.close {
  cursor: pointer;
  color: var(--color-text);
}

.v-app-bar.v-app-bar--fixed {
  z-index: 9;
}
.active-button {
  opacity: unset;
}
</style>

<style>
div.v-toolbar__extension {
  background-color: var(--color-accent);
  color: var(--color-text);
  font-weight: bold;
  align-items: center;
  padding: 16px 16px 16px 40px;
}
</style>