import Vue from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import router from './router'


// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig =  require('./firebaseConfig.json');

console.log("firebaseConfig: " , firebaseConfig);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    vuetify,
    i18n,
    store,
    router
}).$mount('#app')

