const notification = {
  state: () => ({
    text: null,
    type: null,
  }),
  mutations: {
    setNotification(state, notification) {
      state.text = notification.text;
      state.type = notification.type;
    },
  },
  actions: {
    createErrorNotification({ dispatch }, { text }) {
      dispatch("createNotification", { text, type: "error" });
    },
    createNotification({ commit }, { text, type = "success" }) {
      commit("setNotification", { text, type });

      setTimeout(() => {
        commit("setNotification", { text: null, type: null });
      }, 5000)
    },
    closeNotification({ commit }) {
      commit("setNotification", { text: null, type: null });
    },
  },
  getters: {
    notification: state => { return { text: state.text, type: state.type } },
  }
};

export default notification;