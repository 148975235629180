<template>
  <v-snackbar
    :class="$vuetify.breakpoint.mobile && 'mb-5'"
    v-if="notification.text"
    :value="notification.text"
    :multi-line="!$vuetify.breakpoint.mobile"
    :timeout="-1"
    :top="!$vuetify.breakpoint.mobile"
    :right="!$vuetify.breakpoint.mobile"
    :color="colorMap[notification.type]"
  >
    {{ $t(`notification.${notification.text}`) }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" icon v-bind="attrs" @click="closeNotification">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Notification",
  data() {
    return {
      colorMap: {
        success: "success",
        info: "var(--color-gallery)",
        error: "error",
      },
    };
  },
  methods: {
    ...mapActions(["closeNotification"]),
  },
  computed: {
    ...mapGetters(["notification"]),
  },
};
</script>