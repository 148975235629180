import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import notification from './notification'

//import config from '../config.json';

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    user,
    notification
  },
  state: {
    config: null,
    siteConfig: null,

    chainId: null,

    videos: { free: [], auth: [], nft: [] },
    currentVideo: null,

    loadingRequests: 0,
    isLoading: true,
    NFTMissing: false,

    isDrawerOpen: false
  },

  mutations: {
    setConfig(state, config) {
      state.config = config;
    },
    setSiteConfig(state, siteConfig) {
      state.siteConfig = siteConfig;
    },
    setChainId(state, chainId) {
      state.chainId = chainId
    },
    setVideos(state, videos) {
      state.videos = videos;
    },
    setCurrentVideo(state, video) {
      state.currentVideo = video
    },
    isNFTMissing: (state, NFTMissing) => {
      state.NFTMissing = NFTMissing
    },
    startLoading: (state) => {
      state.loadingRequests++;
      state.isLoading = true;
    },
    endLoading: (state) => {
      if (state.loadingRequests > 0) {
        state.loadingRequests--;
      }
      if (state.loadingRequests == 0) {
        state.isLoading = false;
      }
    },
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen
    },
    setIsDrawerOpen : (state, isOpen) => {
      state.isDrawerOpen = isOpen
    } 

  },

  actions: {
    async loadConfig({ commit }) {
      const resp = await fetch("/config.json");
      const config = await resp.json();
      commit("setConfig", config);
    },

    async loadSiteConfig({ commit, getters }) {
      const resp = await fetch(`${getters.apiUrl}/site-config`);
      const siteConfig = await resp.json();
      commit("setSiteConfig", siteConfig);
    },

    async getVideos({ commit, getters }, token) {
      commit("startLoading");
      const resp = await fetch(`${getters.apiUrl}/videos`, {
        headers: {
          ...(getters.walletInfo?.address) && { 'wallet': getters.walletInfo?.address },
          ...(getters.chainId) && { 'chain': getters.chainId },
          ...(token || getters.userInfo?.accessToken) && { 'token': token ?? getters.userInfo?.accessToken }
        }
      });

      const json = await resp.json();

      let videos = { free: [], auth: [], nft: [] };

      for (const video of json.videos) {
        videos[video.level].push(video);
      }

      commit("setVideos", videos);
      if (getters.walletInfo?.address && json.auth_level < 2) {
        commit("isNFTMissing", true);
      } else {
        commit("isNFTMissing", false);
      }
      commit("endLoading");
    },
    setCurrentVideo({ commit }, video) {
      commit("setCurrentVideo", video);
    },

    setNFTMissing({ commit }, NFTMissing) {
      commit('isNFTMissing', NFTMissing);
    }
  },

  getters: {
    apiUrl: state => state.config["api-url"],
    title: state => state.config["title"],
    siteConfig: state => state.siteConfig,

    tokenUrl: state => state.siteConfig ? state.siteConfig.token_url : null,

    chainId: state => state.chainId,

    videos: state => state.videos,
    currentVideo: state => state.currentVideo,

    isLoading: state => state.isLoading,
    isNFTMissing: state => state.NFTMissing,

    isDrawerOpen: state => state.isDrawerOpen
  }
})