<template>
  <v-menu class="mr-4" offset-y min-width="100px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
      >
        <v-icon color="var(--color-text)">mdi-earth</v-icon>
      </v-btn>
    </template>
    <v-list color="var(--color-bg)">
      <v-list-item dark v-for="lang in langs" :key="lang" :value="lang" @click="$i18n.locale = lang">
        <v-list-item-title v-html="$t(`language.${lang}`)"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'locale',
  data () {
    return { langs: ['en', 'it'] }
  }
}
</script>