<template>
  <v-footer height="100" color="var(--color-bg)" class="px-16">
      <router-link class="link" to="/privacy-policy">{{$t('message.privacy_policy')}}</router-link>
      <router-link class="link" to="/cookie">{{$t('message.cookies')}}</router-link>
      <router-link class="link" to="/legal">{{$t('message.legal')}}</router-link>
      <v-spacer />
      <span class="white--text">&copy; 2022<a class="link" href="http://skillbill.it" target=”_blank”>Skillbill srl</a></span>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.v-application a {
  padding: 0px 8px;
  color: var(--color-text)
}
</style>